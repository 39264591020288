
const getUA = () => {
    const UUA = navigator.userAgent.toUpperCase(),
        isWin = UUA.includes("WINDOWS NT"),
        isWin64 = UUA.includes("WOW64") || UUA.includes("WIN64") || UUA.includes("W0W64"),
        isLinux64 = UUA.includes("X86_64"),
        isIntelMac = UUA.includes("INTEL"),
        isMac = UUA.includes("MAC OS X"),
        isLinux = UUA.includes("LINUX"),
        isAndroid = UUA.includes("ANDROID"),
        isIphone = UUA.includes("IPHONE"),
        isIpad = UUA.includes("IPAD"),
        isX64 = isWin64 || isLinux64 || isIntelMac
    return {isWin, isWin64, isLinux64, isIntelMac, isMac, isLinux, isAndroid, isIphone, isIpad, isX64}
}

export {getUA}